body {}

@media (min-width: 1644px) {
    body {
        overflow-x: hidden;
    }
}

.blind {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    clip-path: polygon(0 0, 0 0, 00);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
}