/* reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 160%;
  font-family: 'Noto Sans KR', Arial, Helvetica, sans-serif;
}

body {
  font-weight: 300;
  font-size: 14px;
  color: #141415;
  line-height: 160%;
  font-family: 'Noto Sans KR', Arial, Helvetica, sans-serif;
  overflow: auto;
}


::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-thumb {
  height: 6px;
  width: 6px;
  background: rgba(0,0,0,.3);
  border-radius: 7px;
  border: 4px solid transparent;
  background-clip: content-box;
  box-sizing: border-box;
}

textarea {
  overflow: auto;
}

textarea::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

textarea::-webkit-scrollbar-button {
  display: none;
}

textarea::-webkit-scrollbar-track {
  background: rgba(211, 211, 211, .9);
  border-radius: 5px;
}

textarea::-webkit-scrollbar-thumb {
  height: 6px;
  width: 6px;
  background: rgba(163, 163, 163, .5);
  border-radius: 5px;
  border: none;
  background-clip: content-box;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: #141415;
}

p {
  margin-block: 0;
}

.jodit__preview-box p {
  display: flex;
}
.jodit__preview-box img {
  vertical-align: bottom;
}

.jodit-context table,
.jodit-wysiwyg table {
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

button,
a,
select,
input,
textarea {
  /*outline: 0;*/
  font-weight: 300;
  font-size: 14px;
  color: #141415;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
textarea,
button,
select {
  box-sizing: border-box;
  font-family: 'Noto Sans KR', Arial, Helvetica, sans-serif;
}

/* for popper offset */
div:has(> .react-datepicker) {
  line-height: 0;
}

.react-datepicker {
  line-height: initial;
}
